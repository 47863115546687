import { useMemo, useRef } from 'react';

//* Style
import RelatedSectionStyle from './style';

//* HOC's
import { withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import arrow_lottie_file from '@/lottieFiles/arrow.json';

//* Components
import NewsItem from '../NewsItem';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import ScrollActive from '@/components/global/ScrollActive';

const RelatedSection = ({ title = 'relatedNews', btnUrl = config.routes.news.path, data, winWidth }) => {
	//! Ref
	const titleRef = useRef();

	//! Responsive data
	const responsiveData = useMemo(() => {
		return winWidth >= 1280 && winWidth <= 1535 ? data.slice(0, 3) : data;
	}, [winWidth, data]);

	return data?.length > 0 ? (
		<RelatedSectionStyle>
			<Container
				isSection
				className={'related-wrapper'}>
				<Container
					row
					full
					className={'related-top-wrapper'}>
					<div className='col-6 col-t-9 col-m-12'>
						<ScrollActive
							animTitle
							allRefs={titleRef}>
							<Text
								text={title}
								ref={titleRef}
								tag={'h2'}
								className={'h2 font-montserrat-medium blue-1000-color uppercase opacity-0'}
							/>
						</ScrollActive>
					</div>

					{winWidth >= 768 ? (
						<div className='col-6 col-t-3 col-m-12 btn-lottie-wrapper'>
							{btnUrl ? (
								<Button
									url={btnUrl}
									text={'newsMore'}
									btnType={'lottie'}
									className={'arrow'}
									lottieFiles={arrow_lottie_file}
								/>
							) : null}
						</div>
					) : null}
				</Container>

				<Container
					row
					full
					className={'related-bottom-wrapper'}>
					{responsiveData.map((item, index) => (
						<div
							key={index}
							className='related-news-item col-3 col-dm-4 col-t-6 col-m-12'>
							<NewsItem {...item} />
						</div>
					))}
				</Container>

				{winWidth < 768 ? (
					<div className='btn-lottie-wrapper-mobile'>
						{btnUrl ? (
							<Button
								url={btnUrl}
								text={'newsMore'}
								btnType={'lottie'}
								className={'arrow'}
								lottieFiles={arrow_lottie_file}
							/>
						) : null}
					</div>
				) : null}
			</Container>
		</RelatedSectionStyle>
	) : null;
};

export default withUIContext(RelatedSection, ['winWidth']);
