import styled from 'styled-components';

const NewsItemStyle = styled.div`
	--tagGap: var(--sp1-5x);
	--tagPadTB: var(--sp1-5x);
	--tagPadLR: var(--sp2-5x);
	--wrapperPad: var(--sp3x);
	--bottomWrapperFixHeight: var(--sp31x);
	--bottomWrapperTextMTop: var(--sp2-5x);
	--bottomWrapperDateMTop: var(--sp4x);
	--newsItemBorderRadius: var(--sp4x);
	--newsItemImgSize: 57.2%;

	overflow: hidden;
	border-radius: var(--newsItemBorderRadius);
	border: 1px solid var(--neutral100Color);
	background-color: var(--whiteColor);
	transition: background-color var(--trTime) ease-out;

	.image-relative-block {
		position: relative;
	}

	.top-absolute-wrapper {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.top-absolute-inner-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: var(--tagGap);
		padding: var(--wrapperPad);
	}

	.tag-title {
		width: fit-content;
		background-color: var(--surfaceColor);
		padding: var(--tagPadTB) var(--tagPadLR);
		border-radius: 2000px;
		text-transform: capitalize;
	}

	.image-cont {
		--proportion: var(--newsItemImgSize);
	}

	.bottom-wrapper {
		width: 100%;
		height: var(--bottomWrapperFixHeight);
	}

	.bottom-inner-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: var(--wrapperPad);
	}

	.flex-end-wrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: var(--bottomWrapperDateMTop);
	}

	.title {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	.featured-text {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin-top: var(--bottomWrapperTextMTop);
	}

	.title,
	.featured-text,
	.date {
		transition: color var(--trTime) ease-out;
	}

	/* //! Hover */
	@media (hover: hover) {
		&:hover {
			background-color: var(--successColor);

			.title,
			.featured-text,
			.date {
				color: var(--whiteColor);
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp2x);
		--wrapperPad: var(--sp2-5x);
		--bottomWrapperFixHeight: var(--sp28x);
		--bottomWrapperTextMTop: var(--sp2x);
		--bottomWrapperDateMTop: var(--sp3x);
		--newsItemBorderRadius: var(--sp3x);
		--newsItemImgSize: 58.3%;
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp2x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp27x);
		--bottomWrapperTextMTop: var(--sp2x);
		--bottomWrapperDateMTop: var(--sp3x);
		--newsItemBorderRadius: var(--sp2-5x);
		--newsItemImgSize: 58.7%;
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp22x);
		--bottomWrapperTextMTop: var(--sp2x);
		--bottomWrapperDateMTop: var(--sp2-5x);
		--newsItemBorderRadius: var(--sp2-5x);
		--newsItemImgSize: 57.4%;
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp22x);
		--bottomWrapperTextMTop: var(--sp2x);
		--bottomWrapperDateMTop: var(--sp2-5x);
		--newsItemBorderRadius: var(--sp2-5x);
		--newsItemImgSize: 63.6%;
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp25x);
		--bottomWrapperTextMTop: var(--sp2x);
		--bottomWrapperDateMTop: var(--sp2-5x);
		--newsItemBorderRadius: var(--sp2-5x);
		--newsItemImgSize: 57.7%;
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--tagGap: var(--sp1x);
		--tagPadTB: var(--sp1x);
		--tagPadLR: var(--sp1-5x);
		--wrapperPad: var(--sp2x);
		--bottomWrapperFixHeight: var(--sp25x);
		--bottomWrapperTextMTop: var(--sp2x);
		--bottomWrapperDateMTop: var(--sp2-5x);
		--newsItemBorderRadius: var(--sp2-5x);
		--newsItemImgSize: 57.8%;
	}
`;

export default NewsItemStyle;
