//* Style
import NewsItemStyle from './style';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import CustomImage from '@/components/global/Image';
import CustomLink from '@/components/global/CustomLink';

const NewsItem = ({ title, slug, featured_text, featured_image, categories, regions, date, className }) => {
	return (
		<NewsItemStyle className={className || ''}>
			<CustomLink url={`${config.routes.news.path}/${slug}`}>
				<div className='common-wrapper'>
					<div className='image-relative-block'>
						<CustomImage
							src={featured_image?.src}
							alt={featured_image?.alt}
						/>

						{regions?.length > 0 || categories?.length > 0 ? (
							<div className='top-absolute-wrapper'>
								<div className='top-absolute-inner-wrapper'>
									{categories.map((cat, index) => (
										<div key={index}>
											<Text className={`p p6 font-montserrat-medium blue-1000-color tag-title`}>{cat.name}</Text>
										</div>
									))}

									{regions.length === 5 ? (
										<div key='all'>
											<Text
												className='p p6 font-montserrat-medium blue-1000-color tag-title'
												text={'all'}
											/>
										</div>
									) : (
										regions.map((region, index) => (
											<div key={index}>
												<Text className={`p p6 font-montserrat-medium blue-1000-color tag-title`}>{region.title}</Text>
											</div>
										))
									)}
								</div>
							</div>
						) : null}
					</div>

					<div className='bottom-wrapper'>
						<div className='bottom-inner-wrapper'>
							<div className='bottom-inner-top-wrapper'>
								<Text className={`p p2 font-montserrat-medium blue-1000-color title`}>{title}</Text>

								<Text className={`p p5 font-active-grotesk-normal blue-1000-color featured-text`}>{featured_text}</Text>
							</div>

							<div className='flex-end-wrapper'>
								<Text className={`p p5 font-active-grotesk-normal blue-1000-color date`}>{date}</Text>
							</div>
						</div>
					</div>
				</div>
			</CustomLink>
		</NewsItemStyle>
	);
};

export default NewsItem;
