import styled from 'styled-components';

const RelatedSectionStyle = styled.section`
	--relatedBottomWrapperMTop: var(--sp11x);

	.related-bottom-wrapper {
		margin-top: var(--relatedBottomWrapperMTop);
	}

	.btn-lottie-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--relatedBottomWrapperMTop: var(--sp8x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--relatedBottomWrapperMTop: var(--sp7x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--relatedBottomWrapperMTop: var(--sp6x);

		.related-news-item {
			&:nth-child(4) {
				display: none;
			}
		}
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--relatedBottomWrapperMTop: var(--sp6x);

		.related-news-item {
			&:nth-child(4) {
				display: none;
			}
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--relatedBottomWrapperMTop: var(--sp5x);

		.related-bottom-wrapper {
			.row {
				row-gap: var(--sp5x);
			}
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--relatedBottomWrapperMTop: var(--sp4x);

		.related-bottom-wrapper {
			.row {
				row-gap: var(--sp2x);
			}
		}

		.btn-lottie-wrapper {
			display: none;
		}

		.btn-lottie-wrapper-mobile {
			margin-top: var(--sp4x);
		}
	}
`;

export default RelatedSectionStyle;
